<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.IPTRUSTED')">
          <template v-slot:body>
            <div class="row verifyIp" style="border-bottom: 1px solid #ebedf2;">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('SECURITY.IP')"
                  label-for="input-ip"
                  :invalid-feedback='$t("VALIDATION.IP_INVALID")'
                  :state="validIp"
                >
                  <b-form-input
                    id="input-ip"
                    v-model="ip"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <b-button
                  variant="primary"
                  :disabled="!validIp"
                  @click="verifyIP()"
                  style="margin-top: 25px"
                >
                  {{ $t("SECURITY.VERIFY_IP") }}
                </b-button>
              </div>
            </div>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <b-form-select
                    id="select-domain"
                    v-model="selectDomain.selectedDomain"
                    :options="selectDomain.allDomains"
                    value-field="domain"
                    text-field="domain"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <b-button 
                  variant="primary" 
                  class="mr-4" 
                  @click="searchData()"
                  style="margin-top: 25px"
                >
                  {{ $t("COMMON.SEARCH") }}
                </b-button>
              </div>
            </div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="ipList"
              item-key="id"
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
            <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  @click="deleteTrustedIP(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- ---------- ip status modal------------- -->
            <b-modal
              id="ipStatus"
              ref="ipStatus"
              :title="$t('SECURITY.VERIFY_IP') + ': ' + ip" 
            >
              <b-container fluid>
                <b-row class="my-4">
                  <h5>{{ ipStatus }}</h5>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="primary" v-on:click="openMarkSafeModal()" v-if="showMarkAsSafe">
                  {{ $t("SECURITY.MARK_SAFE") }}
                </b-button>
                <b-button variant="outline-secondary" v-on:click="confirmModalClose('ipStatus')">
                  {{ $t("COMMON.CLOSE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ---------- end ip status modal------------- -->
            <!-- ----------mark ip safe modal------------- -->
            <b-modal
              id="markSafe"
              ref="markSafe"
              :title="$t('SECURITY.MARK_SAFE') + ': ' + markSafeForm.remote_ip"
            >
              <b-container fluid>
                <b-row cols="1">
                  <b-form-group
                  :label="$t('HOME.REMOTE_IP')"
                  label-for="remoteIp"
                  label-cols="12"
                  :invalid-feedback='$t("VALIDATION.IP_INVALID")'
                  :state="validIp"
                  class="mb-0"
                  >
                    <b-form-input
                      id="remoteIp"
                      v-model="markSafeForm.remote_ip"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </b-row>
                <b-row cols="1">
                  <b-form-group
                  :label="$t('COMMON.DOMAIN')"
                  label-for="ipDomain"
                  label-cols="12"
                  class="mb-0"
                  >
                    <model-list-select :list="markSafeForm.domain.data"
                      v-model="markSafeForm.domain.selected"
                      option-value="domain"
                      option-text="domain"
                      :placeholder="$t('COMMON.SEL_DOMAIN')"
                      @input="onChangeDomain"
                      id="ipDomain">
                    </model-list-select>
                  </b-form-group>
                </b-row>
                <b-row cols="1">
                  <b-form-group
                  :label="$t('COMMON.USERNAME')"
                  label-for="ipUser"
                  label-cols="12"
                  class="mb-0"
                  >
                    <model-list-select :list="markSafeForm.username.data"
                      v-model="markSafeForm.username.selected"
                      option-value="value"
                      option-text="text"
                      :placeholder="$t('COMMON.SEL_USER')"
                      id="ipUser">
                    </model-list-select>
                  </b-form-group>
                </b-row>
                <b-row cols="1">
                  <b-form-group
                  :description="$t('SECURITY.IP_DESC')"
                  :label="$t('COMMON.DESCRIPTION')"
                  label-for="ipDescription"
                  label-cols-md="12"
                  :invalid-feedback="invalidIpDescFeedback"
                  :state="validIpDesc"
                  class="mb-0"
                  >
                    <b-form-input
                      id="ipDescription"
                      v-model="markSafeForm.ipDescription"
                    ></b-form-input>
                  </b-form-group>
                </b-row>
                <b-row cols="1">
                  <b-form-group
                  :description="$t('SECURITY.TYPE_DESC')"
                  :label="$t('COMMON.RANGE')"
                  label-for="ipType"
                  label-cols-md="12"
                  class="mb-0"
                  >
                    <b-form-select
                      id="ipType"
                      v-model="markSafeForm.range"
                    >
                      <b-form-select-option value="32">IP: /32</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="confirmModalClose('markSafe')">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="markSafe()" :disabled="!validIpDesc">
                  {{ $t("SECURITY.MARK_SAFE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------end mark ip safe modal------------- -->

            <!-- ---------- delete confirm modal------------- -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DELETE") + ": " + delItem.ip + "?" }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
            
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";

export default {
  name: "trustedips",
  components: {
    KTPortlet,
  },
  data: () => ({
    ip: "",
    ipList: [],
    ipStatus: "",
    markSafeForm: {
      ipDescription: "",
      range: 32,
      username: {
        data: [],
        selected: "",
      },
      domain: {
        data: [],
        selected: "",
      },
      remote_ip: "",
    },
    delItem: "",
    selected:[],
    selectDomain: {
      selectedDomain: "",
      allDomains: [],
    },
    showMarkAsSafe: false,
  }),
  computed: {
    headers: function () {
      return [
        {
          text: this.$t("COMMON.USERNAME"),
          value: "username",
          sortable: false,
        },
        { text: this.$t("COMMON.DOMAIN"), value: "domain", sortable: false },
        { text: this.$t("COMMON.DESCRIPTION"), value: "description", sortable: false },
        {
          text: this.$t("SECURITY.IP"),
          value: "ip",
          sortable: false,
        },
        {
          text: this.$t("COMMON.RANGE"),
          value: "range",
          sortable: false,
        },
        /*{
          text: this.$t("COMMON.TYPE"),
          value: "type",
          sortable: false,
        },*/
        { text: this.$t("COMMON.ACTION"), 
          value: "action", 
          sortable: false, 
        },
      ];
    },
    validIpDesc() {
      let len = this.markSafeForm.ipDescription.length;
      let reg = new RegExp('^[0-9a-zA-Z_.-]+$');
      
      return (len > 1 && len <= 12 && reg.test(this.markSafeForm.ipDescription));
    },
    invalidIpDescFeedback() {
      let len = this.markSafeForm.ipDescription.length;
      if (len < 2) {
        return this.$t("VALIDATION.REQUIRED_MINLENGTH_FIELD", { num: "2" });
      }
      let reg = new RegExp('^[0-9a-zA-Z_.-]+$');
      if (!reg.test(this.markSafeForm.ipDescription)) {
        return this.$t("VALIDATION.REGEX", 
          {rule: this.$t("VALIDATION.LETTERS") + ", "+ this.$t("VALIDATION.NUMBERS") + ", _.-"});
      }
      return this.$t("VALIDATION.MAX_VALUE", { num: "12" });
    },
    validIp() {
      let reg = new RegExp('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
      
      return (this.ip && reg.test(this.ip));
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
      includeAliasDomains: 1,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0].domain;

        this.markSafeForm.domain.data = res.returndata;
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { 
        title:
          this.$t("MENU.SECURITY") +
          " -> " +
          this.$t("MENU.IPTRUSTED"),
      },
    ]);
  },
  methods: {
    /*  -----modal close------- */
    confirmModalClose(modalRef) {
      this.$refs[modalRef].hide();
    },
    verifyIP() {
      postRequest({
        action: "verify_ip_blocked",
        token: localStorage.id_token,
        ip: this.ip,
      }).then((res) => {
        if (res && res.returncode) {
          if (res.returndata == 0) {
            this.ipStatus = this.$t("SECURITY.IP_NOT_BLOCKED");
            this.showMarkAsSafe = false;
          } else {
            this.ipStatus = this.$t("SECURITY.IP_BLOCKED");
            this.showMarkAsSafe = true;
          }
          this.$refs["ipStatus"].show();
        }
      });
    },
    onChangeDomain(){
      this.markSafeForm.username.data = [];
      this.markSafeForm.username.selected = "";
      postRequest({
        action: "getAllUsersDomain",
        token: localStorage.id_token,
        domain: this.markSafeForm.domain.selected,
        offset: 0,
        limit: 0,
        sorting: 0,
        slicing: 0,
        getOTPAndToken: 0,
        showQuota: 0,
      }).then((res) => {
        if (res.returncode) {
          res.returndata.forEach((el) => {
            this.markSafeForm.username.data.push({
              value: el.username,
              text: el.email,
            });
          });
        }
      });
    },
    openMarkSafeModal() {
      this.markSafeForm.username.selected = "";
      this.markSafeForm.domain.selected = "";
      this.markSafeForm.remote_ip = this.ip;
      this.markSafeForm.ipDescription = "";
      this.markSafeForm.range = 32;
      this.$refs["markSafe"].show();
    },
    markSafe() {
      postRequest({
        action: "markIpSafeUnsafe",
        token: localStorage.id_token,
        username: this.markSafeForm.username.selected,
        domain: this.markSafeForm.domain.selected,
        remote_ip: this.markSafeForm.remote_ip,
        netmask: this.markSafeForm.range,
        description: this.markSafeForm.ipDescription,
      }).then((res) => {
        if (res && res.returncode) {
          this.confirmModalClose('markSafe');
          this.confirmModalClose('ipStatus');
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    searchData() {
      postRequest({
        action: "getSafeUnsafeIPs",
        token: localStorage.id_token,
        domain: this.selectDomain.selectedDomain,
      }).then((res) => {
        if (res && res.returncode) {
          this.ipList = res.returndata;
          for (let i in this.ipList) {
            this.ipList[i].range = '/' + this.ipList[i].netmask;
          }
        } else {
            this.ipList = [];
            this.makeToastVariant("danger", res.returndata);
          }
      });
    },

    /* -------delete item----- */
    deleteTrustedIP(item) {
      this.delItem = item;
    },
    deleteRequest(item) {
      postRequest({
        action: "removeSafeUnsafeIPs",
        token: localStorage.id_token,
        id: item.id,
      }).then((res) => {
        if (res && res.returncode) {
          this.makeToastVariant("success", res.returndata);
          this.searchData();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.confirmModalClose("delConfirm-modal");
      });
    },
    deleteOneItem() {
      const item = this.delItem;
      this.deleteRequest(item);
    },
    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
